<script lang="ts" setup>
import type { StrapiResp } from '~/models/Strapi'

const config = useRuntimeConfig()
const isLangSelected = useCookie('isLangSelected', { path: '/', domain: config.public.COOKIE_DOMAIN })
const isLangModalOpen = useCookie('isLangModalOpen', { path: '/', domain: config.public.COOKIE_DOMAIN })
const strapiData = ref()
isLangModalOpen.value = (useGetSubdomain() === undefined && !isLangSelected.value).toString()
getStrapi(useStrapiLocale(useGetSubdomain(), null))

async function getStrapi(lang: string) {
  const { data, error } = await useStrapiAPI<StrapiResp>(`/landing-page?
    populate=*
    &populate[meta][populate]=*
    &populate[components][populate][image][populate]=*
    &populate[components][populate][animation][populate]=*
    &populate[components][populate][testimoni][populate][thumbnail][populate]=*
    &populate[components][populate][testimoni][populate][flag][populate]=*
    &populate[components][populate][top_cta][populate][icon][populate]=*
    &populate[components][populate][items][populate][icon][populate]=*
    &populate[components][populate][icon][populate]=*
    &populate[components][populate][bg_image][populate]=*
    &publicationState=live&locale=${lang}`, {
    key: 'get_homepage',
    initialCache: false,
  })
  if (!isEmpty(data.value)) {
    strapiData.value = data.value.data.attributes
    setMeta(strapiData.value.meta)
  }
  else { throw createError({ statusCode: 404, statusMessage: 'Page Not Found', fatal: true }) }
}

function setMeta(metaVal: any) {
// seo
  const seoTitle = metaVal?.title || ''
  const seoDescription = metaVal?.description || ''
  const keywords = metaVal?.keywords || ''
  const robots = (config.public.ENV === 'development' || config.public.ENV === 'staging') ? 'none' : metaVal?.robots
  const canonical = metaVal?.canonical || ''

  useHead({
    title: seoTitle,
    meta: [
      { property: 'og:title', content: seoTitle },
      { name: 'twitter:title', content: seoTitle },
      { name: 'description', content: seoDescription },
      { property: 'og:description', content: seoDescription },
      { name: 'twitter:description', content: seoDescription },
      { name: 'keywords', content: keywords || null },
      robots ? { name: 'robots', content: robots } : {},
    ],
    link: [
      canonical && { rel: 'canonical', href: canonical },
    ],
  })
}
</script>

<template>
  <BaseAtomsSkeletonBounded container="full">
    <div v-for="(item, idx) in strapiData?.components" :key="idx">
      <component :is="useStrapiComponent(item.__component)" :datas="item" />
    </div>
  </BaseAtomsSkeletonBounded>
</template>
